<template>
  <v-dialog
    v-model="show"
    :fullscreen="isPhone"
    :width="dialogWidth"
    persistent
    overlay-opacity="0.97"
    @keydown.esc="cancel"
  >
    <v-card
      class="tile d-flex flex-column noscroll charcoal "
      v-touch:swipe.left="cancel"
    >
      <BaseCloseDialogHeader heading="Share Workout" @cancel="cancel" />
      <BaseLoading v-if="!loaded" />
      <template v-else>
        <v-sheet
          rounded
          class="charcoal paper--text mb-2 mt-0 py-2 "
          v-if="selectedWorkout != null"
          :style="charcoalTile"
        >
          <v-card-actions :class="textSize + ' py-0 progressActive--text'">
            {{ selectedWorkout.name }}
          </v-card-actions>
          <v-card-actions :class="textSizeXSmall + ' pt-0 silver--text'">
            {{ selectedWorkout.description }}
          </v-card-actions>
          <v-card-actions class="py-3 pa-0 paper">
            <v-spacer />
            <qrcode-vue
              background="#eaeaea"
              foreground="#212b31"
              :value="selectedWorkout.workoutid"
              :size="size"
              level="H"
            />
            <v-spacer />
          </v-card-actions>
          <v-card-text :class="textSizeXSmall + ' px-2 py-1 silver--text'">
            Have you friend scan the QR code above using the "Scan Workout"
            option from "Today's Workout" screen."
          </v-card-text>
          <v-card-actions class="py-0 pr-1 my-n4">
            <v-spacer />
            <v-checkbox
              color="success"
              dark
              off-icon="mdi-chevron-down-circle-outline"
              on-icon="mdi-chevron-up-circle-outline"
              v-model="showWorkoutDetails"
              label="View Exercises"
              title="Last Week: Workout Details"
            />
          </v-card-actions>
          <template>
            <v-card-actions v-if="showWorkoutDetails" class="py-0">
              <v-list dense subheader class="transparent py-0">
                <v-list-item
                  v-for="exercise in selectedWorkout.exercises"
                  :key="exercise.id"
                  class="pl-1 my-0 py-0"
                >
                  <v-badge color="success" left inline :content="exercise.sort">
                    <div
                      :class="
                        textSizeXSmall +
                          ' ml-1 silver--text text--darken-1 link'
                      "
                      :title="'View Details for ' + exercise.name"
                      @click="showExercise(exercise.exerciseid)"
                    >
                      {{ exercise.name }}
                    </div>
                  </v-badge>
                </v-list-item>
              </v-list>
            </v-card-actions>
          </template>
        </v-sheet>
        <v-row
          :class="textSizeLarge + ' py-5 paper--text'"
          align="center"
          justify="center"
        >
          OR
        </v-row>
        <v-sheet
          rounded
          class="charcoal paper--text ma-2 my-2 pa-1 "
          :style="charcoalTileMenu"
        >
          <v-card-actions :class="textSize + ' pb-0'">
            Share By Email
          </v-card-actions>
          <v-text-field
            dark
            label="Friend Email"
            :error-messages="emailErrors"
            color="success"
            filled
            type="email"
            name="email"
            v-model="email"
            required
            prepend-inner-icon="mdi-email"
            :counter="250"
            @input="$v.email.$touch()"
          />
          <v-text-field
            dark
            label="Friend Name"
            :error-messages="nameErrors"
            color="success"
            filled
            type="name"
            name="name"
            v-model="name"
            required
            prepend-inner-icon="mdi-account"
            :counter="250"
            @input="$v.name.$touch()"
            @blur="lname = capitalize(name)"
          />
          <v-card-actions class="pa-0 mt-n2">
            <v-spacer />
            <BaseActionButton
              :disabled="$v.$anyError"
              :large="!isPhone"
              icon="mdi-share"
              :title="'Share Workout: ' + selectedWorkout.name"
              label="Share"
              dark
              text
              color="paper"
              @clickedThis="shareWorkoutByEmail"
            />
          </v-card-actions>
        </v-sheet>
        <v-sheet
          rounded
          class="charcoal paper--text mx-2 mt-2 pa-1 "
          :style="charcoalTileMenu"
        >
          <v-row
            :class="textSizeLarge + ' py-5'"
            align="center"
            justify="center"
          >
            OR
          </v-row>
          <v-card-actions :class="textSize + 'pb-0'">
            Share With Followers
          </v-card-actions>
          <BaseSearch
            v-if="followers"
            dark
            :items="followers"
            :label="followers.length + ' followers'"
            @search_results="setResults"
            @searching="resetResults"
            :searchIn="['follower_name']"
          />
          <v-card-actions class="pa-0">
            <v-spacer />
            <BaseActionButton
              :disabled="!anySelected"
              :large="!isPhone"
              icon="mdi-share"
              :title="'Share Workout: ' + selectedWorkout.name"
              label="Share"
              dark
              text
              color="paper"
              @clickedThis="shareWorkout"
            />
          </v-card-actions>
          <v-list dense subheader class="transparent pa-0">
            <v-list-item
              v-for="follower in followers"
              :key="follower.id"
              class="pa-0"
            >
              <v-row dense>
                <v-col cols="12">
                  <v-checkbox
                    v-model="follower.selected"
                    dark
                    :label="follower.follower_name"
                    @change="checkSelection"
                    class="pa-0 ma-0"
                  />
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-sheet>
      </template>
    </v-card>
    <v-dialog
      v-model="showExerciseInfo"
      width="1024"
      :fullscreen="isPhone"
      overlay-color="charcoal"
      overlay-opacity="0.97"
    >
      <ExerciseInfo
        :exerciseid="selectedid"
        @keydown.esc="cancelInfo"
        @cancel="cancelInfo"
      />
    </v-dialog>
  </v-dialog>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const ExerciseInfo = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/ExerciseInfo.vue')
import { email, maxLength } from 'vuelidate/lib/validators'
import QrcodeVue from 'qrcode.vue'
export default {
  name: 'ShareWorkout',
  components: { ExerciseInfo, QrcodeVue },
  mixins: [util],
  props: {
    show: {
      type: Boolean
    },
    switchNum: {
      type: Number,
      default: 0
    },
    selectedWorkout: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      followers: null,
      loadedList: null,
      email: null,
      name: null,
      showWorkoutDetails: false,
      showExerciseInfo: false,
      selectedid: null,
      selected: null,
      loaded: true,
      anySelected: false,
      size: 150
    }
  },
  watch: {
    switchNum: function() {
      this.loadIncomplete()
    },
    followers: function() {
      this.checkSelection()
    },
    show: function(value) {
      this.$store.dispatch('session/toggleModal', value)
      if (value) this.loadFollowers()
      else this.loaded = false
    }
  },
  beforeMount() {},
  validations: {
    email: { email },
    name: {
      maxLength: maxLength(50)
    }
  },
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Invalid e-mail.')
      return errors
    },

    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.maxLength && errors.push('Invalid name.')
      return errors
    },
    ...appConfig
  },
  methods: {
    checkSelection() {
      this.selected = this.followers.filter(function(item) {
        return item.selected == true
      })
      this.anySelected = this.selected.length > 0
    },
    setResults(value) {
      this.followers = value
    },
    resetResults() {
      this.followers = this.loadedList
    },
    shareWorkout() {
      this.loaded = false
      return axios
        .post(this.baseURL + '/workout/share', {
          workout: this.selectedWorkout,
          followers: this.selected,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            this.toast(
              'Workout ' +
                this.selectedWorkout.name +
                ' was succesfully shared!'
            )
            this.$emit('cancel')
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    shareWorkoutByEmail() {
      this.loaded = false
      return axios
        .post(this.baseURL + '/workout/share/byemail', {
          workout: this.selectedWorkout,
          email: this.email,
          name: this.name,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            this.toast(
              'Workout ' +
                this.selectedWorkout.name +
                ' was succesfully shared!'
            )
            this.$emit('cancel')
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    loadFollowers() {
      this.loaded = false
      return axios
        .get(this.baseURL + '/user/isfollowing', {})
        .then(response => {
          if (response.status == 200) {
            this.followers = response.data.data
            this.followers.forEach(element => {
              element.selected = false
            })
            this.followers = this.followers.filter(function(item) {
              return item.followerid != item.followed_userid
            })
            this.loadedList = [...this.followers]
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    goTo(path) {
      if (this.$route.path != path) this.$router.push(path)
      this.cancel()
    },
    cancelInfo() {
      this.showExerciseInfo = false
    },
    showExercise(id) {
      this.selectedid = id
      this.showExerciseInfo = true
    },

    reset() {},
    cancel() {
      this.reset()
      this.$emit('cancel')
    }
  }
}
</script>
